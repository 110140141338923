import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faRulerCombined, faChevronRight, faChevronLeft, faImage } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { numberFormat, Property } from '../../utils/properties';
import useThemeStore from '../../store/themeStore';
import { ThemeProps } from '../../utils/theme';
import { CUSTOM_CARDS } from '../../pages/components/FeaturedCardsCarousel';
import backgroundPattern from "../../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import useTokenStore from "../../store/store";
import PropertyShareIcons from '../../pages/components/PropertyShareIcons';

interface DetailViewProps {
  properties: Property[];
  onShareClick: (property: Property) => void;
  setIsModalOpen: (value: boolean) => void;
  selectedProductIdSet: (value: string) => void;
}

const DetailView: React.FC<DetailViewProps> = ({ properties, setIsModalOpen, selectedProductIdSet }) => {
  const navigate = useNavigate();
  const getToken = useTokenStore((state) => state.token);
  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);
  const [featuredCards, setFeaturedCards] = useState(CUSTOM_CARDS);
  const [currentImageIndexes, setCurrentImageIndexes] = useState<{ [key: string]: number }>({});
  const [sideImageIndexes, setSideImageIndexes] = useState<{ [key: string]: number[] }>({});


 
  useEffect(() => {
    properties.forEach((property, index) => {
      console.log(`Property ${index + 1} images:`, property.images);
    });

    const shuffleFeaturedCards = () => {
      setFeaturedCards([...CUSTOM_CARDS].sort(() => Math.random() - 0.5));
    };

    const interval = setInterval(shuffleFeaturedCards, 15000);

    const initialSideIndexes = properties.reduce((acc, property) => {
      acc[property._id] = [1, 2];
      return acc;
    }, {} as { [key: string]: number[] });
    setSideImageIndexes(initialSideIndexes);

    return () => clearInterval(interval);
  }, [properties]);

  const handleCardClick = (id: string) => {
    if (getToken) {
      navigate(`/listing/${id}`);
    } else {
      // Store the intended property ID in session storage
      sessionStorage.setItem('intendedPropertyId', id);
      navigate('/login');
    }
  };

  useEffect(() => {
    const intendedPropertyId = sessionStorage.getItem('intendedPropertyId');
    if (intendedPropertyId && getToken) {
      navigate(`/listing/${intendedPropertyId}`);
      sessionStorage.removeItem('intendedPropertyId');
    }
  }, [getToken, navigate]);





  const goToPrevious = (propertyId: string, imagesLength: number) => {
    setCurrentImageIndexes(prev => {
      const currentIndex = prev[propertyId] !== undefined ? prev[propertyId] : 0;
      const newIndex = (currentIndex - 1 + imagesLength) % imagesLength;
      return {
        ...prev,
        [propertyId]: newIndex
      };
    });
    updateSideImages(propertyId, imagesLength, -1);
  };

  const goToNext = (propertyId: string, imagesLength: number) => {
    setCurrentImageIndexes(prev => {
      const currentIndex = prev[propertyId] !== undefined ? prev[propertyId] : 0;
      const newIndex = (currentIndex + 1) % imagesLength;
      return {
        ...prev,
        [propertyId]: newIndex
      };
    });
    updateSideImages(propertyId, imagesLength, 1);
  };

  const updateSideImages = (propertyId: string, imagesLength: number, direction: number) => {
    setSideImageIndexes(prev => {
      const [firstSide, secondSide] = prev[propertyId];
      return {
        ...prev,
        [propertyId]: [
          (firstSide + direction + imagesLength) % imagesLength,
          (secondSide + direction + imagesLength) % imagesLength
        ]
      };
    });
  };

  const handleSideImageClick = (propertyId: string, index: number) => {
    setCurrentImageIndexes(prev => ({
      ...prev,
      [propertyId]: index,
    }));
  };

  const renderPropertyDescription = (property: Property) => {
    let description = (
      <p className="text-sm">
        A {property.bedrooms} bedroom {property.tenureType} property currently for listed for the purchase price of £{numberFormat(property.price as unknown as number)}.
      </p>
    );

    const getArticle = (strategy: string) => {
      const lowercaseStrategy = strategy.toLowerCase();
      if (lowercaseStrategy === 'housing association') {
        return '';
      } else if (['hmo', 'apartment', 'apartment block'].includes(lowercaseStrategy)) {
        return 'an';
      }
      return 'a';
    };

    const getStrategyText = (strategy: string) => {
      if (strategy.toLowerCase() === 'housing association') {
        return `${strategy} stock`;
      }
      return strategy;
    };

    if (property.discountToMarket > 0) {
      return (
        <>
          {description}
          <p className="text-sm mt-2">
            This property is currently at a {property.discountToMarket}% discount to current area asking prices.
          </p>
        </>
      );
    } else if (property.strategy && property.occupancy && property.occupancy.toLowerCase().includes('tenanted')) {
      const article = getArticle(property.strategy);
      const strategyText = getStrategyText(property.strategy);
      return (
        <>
          {description}
          <p className="text-sm mt-2">
            Current usage is as {article} {article && ' '}{strategyText} and the property is sold as {property.occupancy}.
          </p>
        </>
      );
    } else if (property.strategy && property.floorsqft) {
      const article = getArticle(property.strategy);
      const strategyText = getStrategyText(property.strategy);
      return (
        <>
          {description}
          <p className="text-sm mt-2">
            The property current usage is as {article} {article && ' '}{strategyText} and is {property.floorsqft} sq ft.
          </p>
        </>
      );
    }

    return description;
  };

  return (
    <div className="space-y-4" style={{ width: '97%', margin: '0 auto' }}>
      {properties.map((property, index) => {
        const images = property.images || [];
        const currentMainIndex = currentImageIndexes[property._id] || 0;
        const [firstSideIndex, secondSideIndex] = sideImageIndexes[property._id] || [1, 2];

        return (
          <React.Fragment key={property._id}>
            <div className="flex flex-col lg:flex-row items-start border-gray-300 border bg-white p-1 shadow-md rounded-md space-y-4 lg:space-y-0 lg:space-x-4 hover:shadow-xl transition duration-150 ease-in-out">
              <div className="relative w-full lg:w-2/3 h-72 lg:h-72 overflow-hidden">
                <div className="flex h-full">
                  <div className={`flex-grow relative ${images.length === 1 ? 'w-full' : 'w-3/4'}`}>
                    <img
                      src={images[currentMainIndex]?.url || "https://via.placeholder.com/800x600"}
                      alt={property.propertyOf}
                      className={`w-full h-full object-cover rounded-l-lg ${images.length === 1 ? 'rounded-r-lg' : ''}`}
                    />
                    {images.length > 1 && (
                      <>
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            goToPrevious(property._id, images.length);
                          }}
                          className="absolute top-1/2 left-2 transform -translate-y-1/2 text-black p-2 rounded-md shadow-md transition-all duration-300 ease-in-out"
                          style={{
                            backgroundColor: 'white',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = getThemes?.color2 || 'gray';
                            e.currentTarget.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = 'white';
                            e.currentTarget.style.color = 'black';
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            goToNext(property._id, images.length);
                          }}
                          className="absolute top-1/2 right-2 transform -translate-y-1/2 text-black p-2 rounded-md shadow-md transition-all duration-300 ease-in-out"
                          style={{
                            backgroundColor: 'white',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = getThemes?.color2 || 'gray';
                            e.currentTarget.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = 'white';
                            e.currentTarget.style.color = 'black';
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      </>
                    )}
                    <div className="absolute bottom-2 left-2 bg-white text-black px-3 py-1 rounded-md flex items-center space-x-2 shadow-lg" style={{backgroundColor: "white"}}>
                      <FontAwesomeIcon icon={faImage} />
                      <span>{`${currentMainIndex + 1}/${images.length}`}</span>
                    </div>
                    </div>
                    {images.length > 1 && (
                    <div className="hidden lg:flex flex-col w-1/4 ml-2 space-y-2">
                      <div className="h-1/2 overflow-hidden rounded-tr-md">
                        <img
                          src={images[firstSideIndex]?.url || "https://via.placeholder.com/200x300"}
                          alt={`Side view 1 of ${property.propertyOf}`}
                          className="w-full h-full object-cover cursor-pointer"
                          onClick={() => handleSideImageClick(property._id, firstSideIndex)}
                        />
                      </div>
                      <div className="h-1/2 overflow-hidden rounded-br-md">
                        <img
                          src={images[secondSideIndex]?.url || "https://via.placeholder.com/200x300"}
                          alt={`Side view 2 of ${property.propertyOf}`}
                          className="w-full h-full object-cover cursor-pointer"
                          onClick={() => handleSideImageClick(property._id, secondSideIndex)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-2 w-full lg:w-1/3 cursor-pointer" onClick={() => handleCardClick(property._id)}>
                <div className="flex flex-col h-full">
                  <div className="flex-grow">
                    <div className="flex flex-col items-start w-full">
                      <h3 className="text-lg font-bold pt-2 w-full text-left whitespace-nowrap overflow-hidden overflow-ellipsis" 
                          style={{ fontSize: 'clamp(0.9rem, 1.5vw, 1.1rem)' }}>
                        {property.propertyOf}
                      </h3>
                      <p className="text-gray-600 w-full text-left whitespace-nowrap overflow-hidden overflow-ellipsis"
                        style={{ fontSize: 'clamp(0.7rem, 1.2vw, 0.9rem)' }}>
                        {property.fullAddress}
                      </p>
                      <span className="text-l font-semibold text-blue-500 mt-1">£{numberFormat(property.price as unknown as number)}
                      {property.reserved && (
                          <span className="text-xs font-bold text-white py-1 px-2 rounded-md mr-2 ml-2"
                            style={{
                              background: `${getThemes?.color2}`,
                              color: `${getThemes?.txtColor}`,
                            }}>
                            Reserved
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="mt-2 pt-2">
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon={faBed} />
                        <span>{property.bedrooms}</span>
                        {property.floorsqft && (
                          <>
                            <span className="text-gray-400">|</span>
                            <FontAwesomeIcon icon={faRulerCombined} />
                            <span>{property.floorsqft}</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 pt-2">
                      {property.discountToMarket > 0 && (
                        <span 
                          className="text-xs font-bold text-white py-1 px-2 rounded-md"
                          style={{
                            background: `${getThemes?.color1}`,
                            color: `${getThemes?.txtColor}`,
                          }}
                        >
                          {property.discountToMarket}% Discount
                        </span>
                      )}
                      {property.tenureType && (
                        <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                          style={{
                            background: `${getThemes?.color1}`,
                            color: `${getThemes?.txtColor}`,
                          }}>
                          {property.tenureType}
                        </span>
                      )}
                      {property.occupancy && property.occupancy.toLowerCase() === 'tenanted' && (
                        <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                          style={{
                            background: `${getThemes?.color1}`,
                            color: `${getThemes?.txtColor}`,
                          }}>
                          Tenanted
                        </span>
                      )}
                      <span className="text-xs font-bold text-white py-1 px-2 rounded-md"
                        style={{
                          background: `${getThemes?.color1}`,
                          color: `${getThemes?.txtColor}`,
                        }}>
                        {property.strategy}
                      </span>
                    </div>
                    <div className="bg-gray-100 p-2 rounded-md mt-4 text-left">
                      {renderPropertyDescription(property)}
                    </div>
                  </div>
                  <div className="flex justify-end items-center gap-2 mt-2 relative">
                  <PropertyShareIcons propertyId={property._id} />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        navigate(`/listing/${property._id}`);
                      }}
                      className="focus:outline-none text-blue-500 pr-1"
                      style={{ transition: 'transform 0.2s', fontSize: '1.1rem' }}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.3)')}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
              </div>
              </div>
            </div>
            {(index + 1) % 10 === 0 && featuredCards[Math.floor(index / 10) % featuredCards.length] && (
              <div
                key={`featured-${index}`}
                className="flex flex-row shadow-md rounded-lg overflow-hidden mt-6"
                style={{
                  backgroundImage: `url(${backgroundPattern})`,
                  backgroundColor: '',
                  backgroundRepeat: 'repeat',
                  backgroundSize: '60px 60px',
                }}
              >
                <div className="flex-shrink-0 w-2/4 h-70">
                  <img 
                    src={featuredCards[Math.floor(index / 10) % featuredCards.length].logo} 
                    alt={featuredCards[Math.floor(index / 10) % featuredCards.length].title} 
                    className="w-full h-full object-cover" 
                  />
                </div>
                <div className="flex-grow flex justify-center items-center mt-5 mb-5">
                  <div className="flex flex-col justify-between p-4 border rounded-xl border-black shadow-lg" style={{ width: "85%", backgroundColor: "white" }}>
                    <h2 className="text-xl font-bold mb-2">{featuredCards[Math.floor(index / 10) % featuredCards.length].title}</h2>
                    <ul className="list-disc list-inside space-y-1">
                      {featuredCards[Math.floor(index / 10) % featuredCards.length].bulletPoints.map((point, index) => (
                        <li key={index} className="text-sm">{point}</li>
                      ))}
                    </ul>
                    <button
                      onClick={() => {
                        selectedProductIdSet(featuredCards[Math.floor(index / 10) % featuredCards.length].productId);
                        setIsModalOpen(true);
                      }}
                      style={{
                        backgroundColor: getThemes.color2,
                        color: getThemes.txtColor,
                      }}
                      className="mt-4 w-full text-center py-1 px-2 transition duration-300 ease-in-out rounded text-sm"
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = getThemes?.color1 || "alternateHoverColor";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = getThemes?.color2 || "defaultColor";
                      }}
                    >
                      Enquire Here
                    </button>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DetailView;