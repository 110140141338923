import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { registerAndLoginUser, ResultLoginProps } from "../utils/user";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useThemeStore from "../store/themeStore";
import { ThemeProps } from "../utils/theme";
import useTokenStore from "../store/store";
import backgroundPattern from "../images/vesta/vesta-property-pattern-01-teal-bg.svg";
import Footer from "../components/headerFooter/Footer";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword?: string;
};

function Userregister() {
  const [loading, setLoading] = useState(false);
  const [, setFormSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState<ResultLoginProps>({
    message: "",
    status: 0,
    token: "",
    user: {
      id: "",
      fullname: "",
      email: "",
      userHandle: "",
    },
  });
  const navigate = useNavigate();
  const setToken = useTokenStore((state) => state.setToken);
  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .min(3, "First Name must be at least 3 characters long"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(3, "Last Name must be at least 3 characters long"),
    email: Yup.string()
      .required("Email is required")
      .min(3, "Email must be at least 3 characters long")
      .email("Email is invalid"),
    phone: Yup.string()
      .required("Phone is required")
      .min(10, "Phone must be at least 10 characters long"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>(formOptions);

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    setLoading(true);
    setFormSubmit(true);
    delete data.confirmPassword;
    const response = await registerAndLoginUser(data);
    setMessage(response);
    setLoading(false);

    if (response.status === 200) {
      setToken(response.token);
      setShowModal(true); // Show the modal
      setTimeout(() => {
        setShowModal(false); // Hide the modal
        navigate("/listing"); // Navigate to the listings page
      }, 3000); // Display modal for 3 seconds
    }
  };

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  const getToken = useTokenStore((state) => state.token);
  useEffect(() => {
    if (getToken) {
      navigate("/listing");
    }
  }, [getToken, navigate]);

  useEffect(() => {
    const setBodyStyles = () => {
      document.body.style.display = "flex";
      document.body.style.flexDirection = "column";
      document.body.style.minHeight = "100vh";
      document.body.style.margin = "0";
      document.body.style.position = "relative";
      document.body.style.overflow = "auto";
    };

    setBodyStyles();

    return () => {
      document.body.style.display = "";
      document.body.style.flexDirection = "";
      document.body.style.minHeight = "";
      document.body.style.margin = "";
      document.body.style.position = "";
      document.body.style.overflow = "";
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="userregister"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
        overflow: "auto",
      }}
    >
      <Helmet>
        <title>Register | {getThemes && getThemes.name}</title>
      </Helmet>

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backgroundPattern})`,
          backgroundRepeat: "repeat",
          backgroundSize: "60px 60px",
          zIndex: -1,
        }}
      ></div>

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px",
          paddingTop: isMobile ? "160px" : "100px", // Add top padding for mobile and standard screens
          paddingBottom: "80px", // Add bottom padding for mobile
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white flex flex-wrap justify-center pt-8 pb-8 px-4"
          style={{
            width: "85%",
            maxWidth: "500px",
            border: "1px solid #ddd",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
            backgroundColor: "white",
            marginBottom: "20px",
          }}
        >
          <div className="w-full">
            <p className="text-3xl font-roboto font-medium pb-8 pt-8 text-center">Register</p>
            <div className="w-full py-2">
              <div className="grid grid-cols-2 gap-4 py-2">
                <div className="">
                  <label
                    htmlFor="input-label-with-helper-text"
                    className="block text-base font-medium mb-2 text-left"
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    First Name *
                  </label>
                  <input
                    type="text"
                    id="input-label-with-helper-text"
                    className="py-3 px-4 block w-full rounded-md text-sm"
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      color: "#2D3748",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="First Name"
                    aria-describedby="hs-input-helper-text"
                    {...register("firstName", { required: true })}
                  />

                  <small className="flex justify-start pt-3 text-red-hover-ce312b">
                    {errors.firstName && <span>First Name is required</span>}
                  </small>
                </div>
                <div className="">
                  <label
                    htmlFor="input-label-with-helper-text"
                    className="block text-base font-medium mb-2 text-left"
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Last Name *
                  </label>
                  <input
                    type="text"
                    id="input-label-with-helper-text"
                    className="py-3 px-4 block w-full rounded-md text-sm"
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      color: "#2D3748",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Last Name"
                    aria-describedby="hs-input-helper-text"
                    {...register("lastName", { required: true })}
                  />

                  <small className="flex justify-start pt-3 text-red-hover-ce312b">
                    {errors.lastName && <span>Last Name is required</span>}
                  </small>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 py-2">
                <div className="">
                  <label
                    htmlFor="input-label-with-helper-text"
                    className="block text-base font-medium mb-2 text-left"
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Phone No. *
                  </label>
                  <input
                    type="tel"
                    id="input-label-with-helper-text"
                    className="py-3 px-4 block w-full rounded-md text-sm"
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      color: "#2D3748",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Phone Number"
                    aria-describedby="hs-input-helper-text"
                    {...register("phone", { required: true })}
                  />

                  <small className="flex justify-start pt-3 text-red-hover-ce312b">
                    {errors.phone && <span>Phone Number is required</span>}
                  </small>
                </div>
                <div className="">
                  <label
                    htmlFor="input-label-with-helper-text"
                    className="block text-base font-medium mb-2 text-left"
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    E-mail *
                  </label>
                  <input
                    type="email"
                    id="input-label-with-helper-text"
                    className="py-3 px-4 block w-full rounded-md text-sm"
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      color: "#2D3748",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Email Address"
                    aria-describedby="hs-input-helper-text"
                    {...register("email", { required: true })}
                  />

                  <small className="flex justify-start pt-3 text-red-hover-ce312b">
                    {errors.email && <span>Email Address is required</span>}
                  </small>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 py-2">
                <div className="">
                  <label
                    htmlFor="input-label-with-helper-text"
                    className="block text-base font-medium mb-2 text-left"
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Password *
                  </label>
                  <input
                    type="password"
                    id="input-label-with-helper-text"
                    className="py-3 px-4 block w-full rounded-md text-sm"
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      color: "#2D3748",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Password"
                    aria-describedby="hs-input-helper-text"
                    {...register("password", { required: true })}
                  />

                  <small className="flex justify-start pt-3 text-red-hover-ce312b">
                    {errors.password && <span>Password is required</span>}
                  </small>
                </div>
                <div className="">
                  <label
                    htmlFor="input-label-with-helper-text"
                    className="block text-base font-medium mb-2 text-left"
                    style={{
                      color: "black",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Confirm Pass. *
                  </label>
                  <input
                    type="password"
                    id="input-label-with-helper-text"
                    className="py-3 px-4 block w-full rounded-md text-sm"
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.375rem",
                      padding: "0.75rem",
                      color: "#2D3748",
                      backgroundColor: "#FFF",
                    }}
                    placeholder="Confirm Password"
                    aria-describedby="hs-input-helper-text"
                    {...register("confirmPassword", { required: true })}
                  />

                  <small className="flex justify-start pt-3 text-red-hover-ce312b">
                    {errors.confirmPassword && (
                      <span>{errors.confirmPassword.message}</span>
                    )}
                  </small>
                </div>
              </div>
            </div>

            {message.status === 0 && (
              <div className="mt-4">
                <button
                  type="submit"
                  style={{
                    width: "30%",
                    background: getThemes.color1,
                    color: getThemes.txtColor,
                    padding: "10px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    transition: "background 0.3s ease",
                    boxSizing: "border-box",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.background = getThemes.color2)
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.background = getThemes.color1)
                  }
                >
                  Sign Up
                  {loading && (
                    <span
                      className="animate-spin border-btnscolor inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                      role="status"
                      aria-label="loading"
                      style={{ marginLeft: "10px" }}
                    ></span>
                  )}
                </button>

                <Link to="/login">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center gap-2 font-medium tracking-wide hover:underline py-2"
                  >
                    Or if you are already a member, you can Log In Here
                  </button>
                </Link>
              </div>
            )}
          </div>
        </form>

        {/* Modal for Registration Complete */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h2 className="text-2xl font-bold mb-4">Registration Complete</h2>
              <p>You have successfully registered and logged in.</p>
              <p>Redirecting you to the listings page...</p>
            </div>
          </div>
        )}

        {/* Modal for Errors */}
        {message.status !== 0 && message.status !== 200 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h2 className="text-xl font-bold mb-4">{message.message}</h2>
              <button
                onClick={() => setMessage({ ...message, status: 0 })}
                style={{
                  width: "100px",
                  background: getThemes.color1,
                  color: getThemes.txtColor,
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  transition: "background 0.3s ease",
                  boxSizing: "border-box",
                }}
                onMouseOver={(e) => (e.currentTarget.style.background = getThemes.color2)}
                onMouseOut={(e) => (e.currentTarget.style.background = getThemes.color1)}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Userregister;
